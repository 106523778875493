import cn from 'classnames';
import { useRouter } from 'next/router';
import type { SyntheticEvent } from 'react';
import { memo } from 'react';

import { Badge, Icon, Typography } from '@sravni/react-design-system';
import { Star, Tooltip } from '@sravni/react-icons';
import { useBoolean } from '@sravni/react-utils';
import pluralize from '@sravni/utils/lib/pluralize';

import type { IClientMicrocreditListItem } from '@src/@types/clienttypes';
import { Currency } from '@src/@types/currency';
import type { AffSub4, IFilters } from '@src/@types/microcredits';
import { CurtainPopup } from '@src/components/CurtainPopup';
import { Logo } from '@src/components/Logotype';
import { Pixel } from '@src/components/Pixel';
import { AdditionalConditions } from '@src/components/ProductList/components/CardV2/components/AdditionalConditions';
import { NoAdditionalConditions } from '@src/components/ProductList/components/CardV2/components/NoAdditionalConditions';
import { getAdditionalConditionsCardText } from '@src/components/ProductList/components/CardV2/utils/getAdditionalConditionsCardText';
import { RedirectButton } from '@src/components/RedirectButton';
import { sendAdditionalConditionsClickEvent } from '@src/helpers/analyticsEvents';
import { getAmountString } from '@src/helpers/common';
import { isSpecialPageWithoutQuery } from '@src/utils/routing';

import { useCardClick } from '../Card/useCardClick';

import { ApprovalProbability } from './components/ApprovalProbability';
import { ContentItem } from './components/ContentItem';
import styles from './styles.module.scss';

type Props = {
    className?: string;
    offer: IClientMicrocreditListItem;
    position: number;
    query?: Pick<IFilters, 'term' | 'amount'>;
};

// eslint-disable-next-line max-statements
export const CardV2 = memo(({ className, offer, position, query }: Props) => {
    const [isAdditionalConditionsOpen, setAdditionalConditionsOpen] = useBoolean(false);

    const { name, organization, advertising, approvalProbability, _id, rateRange } = offer;
    const { rating } = organization;
    const { isPartner, labelText } = advertising || {};
    const [label] = labelText?.split(';') || [];
    const router = useRouter();
    const { asPath } = router;
    const isSpecial = isSpecialPageWithoutQuery(asPath);

    const badges = [];

    if (isPartner) {
        const text = 'Mы рекомендуем';
        badges.push(<Badge key={text} color="orange" variant="primary" text={text} />);
    } else if (label) {
        badges.push(<Badge key={label} color="blue" variant="primary" text={label} />);
    }

    const additionalConditionsBadge = getAdditionalConditionsCardText(offer?.additionalServices);

    const amount = getAmountString(query.amount, Currency.RUB);

    const { sourceLink, pixelDisplayLink, logoLink, detailsProductLink } = useCardClick({
        offer,
        position,
    });

    const source = `${sourceLink}|position_${position}`;

    const affSub4: AffSub4 = {
        organizationName: organization.name,
        productName: name,
        organizationId: organization._id,
        productId: _id,
    };

    const hasAdditionalConditions = offer.additionalServices?.exist;

    const pluralizeTerm = pluralize(query.term, 'день', 'дня', 'дней');

    const annualRate = rateRange.to * 365;
    const numberOfDays = query.term;
    const overpayment = ((query.amount * (annualRate / 100)) / 365) * numberOfDays;
    const overpaymentText = getAmountString(overpayment, Currency.RUB);

    const amountAndTerm = `${amount} на ${query.term} ${pluralizeTerm}`;

    const eventLabel = `${organization.name}|${name}|${advertising.offerId}|${source}`;

    const handleTooltipClick = (event: SyntheticEvent) => {
        event.stopPropagation();
        setAdditionalConditionsOpen.on();

        const eventActionValue = `${additionalConditionsBadge}|Открытие из Витрины`;
        sendAdditionalConditionsClickEvent(eventLabel, eventActionValue);
    };

    const handleCompleteButtonClick = (event: SyntheticEvent) => {
        event.stopPropagation();
        setAdditionalConditionsOpen.off();

        const eventActionValue = `${additionalConditionsBadge}|Кнопка понятно`;
        sendAdditionalConditionsClickEvent(eventLabel, eventActionValue);
    };

    return (
        <div className={cn(styles.cardWrapper, className)}>
            <div className={cn(styles.card)} data-qa="Card">
                <div className={styles.wrapper}>
                    <div className={cn(styles.header)}>
                        <div className={styles.text}>
                            <div className={styles.text}>{organization.name}</div>
                            {rating && (
                                <div className={styles.ratingBlock}>
                                    <Icon className={styles.star} size={16} color="orange" icon={<Star />} />
                                    <span>{rating.toFixed(2)}</span>
                                </div>
                            )}
                        </div>
                    </div>

                    {!!badges.length && !isSpecial && <div>{badges}</div>}
                    <div className={styles.card_content}>
                        {pixelDisplayLink && <Pixel source={source} link={pixelDisplayLink} affSub4={affSub4} />}
                        <div className={styles.content_row}>
                            <ContentItem title="Cумма и срок">{amountAndTerm}</ContentItem>
                            <ContentItem title="Переплата">{overpaymentText}</ContentItem>
                            <ContentItem title="Вероятность одобрения">
                                <ApprovalProbability category={approvalProbability?.category} />
                            </ContentItem>
                            <ContentItem title="Доп. услуги">
                                {additionalConditionsBadge}
                                <Icon
                                    className={styles.valueIcon}
                                    size={20}
                                    icon={<Tooltip onClick={handleTooltipClick} />}
                                />
                            </ContentItem>
                        </div>
                    </div>
                </div>
                <Logo
                    buttonEventLabel={eventLabel}
                    className={styles.header_logo}
                    link={{
                        href: `${detailsProductLink.as}?amount=${query.amount}&term=${query.term}`,
                        withAdvertising: false,
                    }}
                    logo={organization.logotypes?.android}
                    isPartner={offer.isBankPartner || offer.advertising?.isPartner}
                    size={36}
                />
                <div className={styles.actions}>
                    <RedirectButton
                        className={styles.button}
                        link={`${detailsProductLink.as}?amount=${query.amount}&term=${query.term}`}
                        buttonEventLabel={eventLabel}
                    />
                </div>
                {offer.advertising && (
                    <Typography.Text className={styles.disclaimer}>
                        Реклама {offer.organization.fullName || offer.organization.name}
                    </Typography.Text>
                )}
                <CurtainPopup
                    visible={isAdditionalConditionsOpen}
                    onClose={setAdditionalConditionsOpen.off}
                    title="Дополнительные услуги"
                    onComplete={handleCompleteButtonClick}
                >
                    {hasAdditionalConditions ? (
                        <AdditionalConditions additionalServices={offer.additionalServices} />
                    ) : (
                        <NoAdditionalConditions />
                    )}
                </CurtainPopup>
            </div>
        </div>
    );
});
