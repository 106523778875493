export const ONE_LINK =
    'https://sravni.onelink.me/cyn1?pid=sravni.ru&af_channel=display&c=mfo_referral_web_to_app&af_adset=main_banner&is_retargeting=true&af_android_store_csl=mfo_full_deal&af_reengagement_window=30d';

export const WEB_FULL_DEAL_LINK = '/mfo/credit/';

export const FULL_DEAL_ENTRY_POINT_TEXTS = {
    heading: 'Не хотите искать займ?',
    description: 'Заполните одну анкету, а мы найдем займ с одобрением',
    redirectButtonText: 'Отправить заявку',
    mobileAppButtonText: 'Мои займы',
    newDealButtonText: 'Новый займ',
};
