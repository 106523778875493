import { ListKeys } from '@src/constants/microcredits';

// на промо-страницах идет дублирование продуктов
export const getKey = (key: string, index: number, listKey: ListKeys) => {
    const isPromo = [ListKeys.GURULEADS, ListKeys.LEADSSU].includes(listKey);
    if (!isPromo) {
        return key;
    }
    return `${key}-${index}`;
};
