import cn from 'classnames';
import { useRouter } from 'next/router';
import type { FC } from 'react';
import { memo, useCallback } from 'react';

import { Layout } from '@sravni/react-design-system';
import { useIsPhone } from '@sravni/react-utils';

import { usePageContext } from '@src/config/PageContext';
import { BANNER_LIST_POSITION_INDEX, BANNER_TYPE } from '@src/constants/banners';
import { getBanners } from '@src/helpers/banners';
import type { IFilter } from '@src/hooks/useFilters';
import { useBanners } from '@src/reducers/banners';
import { useSelectCreditsList } from '@src/reducers/microcredits';
import {
    specOffersLandingPageRouteWebbankirWb1,
    specOffersLandingPageRouteWebbankirWb2,
} from '@src/server/routes/constants';
import { isMainPageWithoutDynamicRoute, isNaKartuPage } from '@src/utils/routing';

import Card from './components/Card';
import { CardV2 } from './components/CardV2';
import { MessageBlock } from './components/MessageBlock';
import Preloader from './components/Preloader';
import { ADDITIONAL_BADGES_FOR_MAIN_PAGE_CARDS } from './constants';
import styles from './styles.module.scss';
import { getKey, getEmptyProductsTexts, getErrorText, addBadgeForCard } from './utils';

interface IProps {
    className?: string;
    preloadTotal?: number;
    filter: IFilter;
}

export const ProductList: FC<IProps> = memo((props: IProps) => {
    const { className, preloadTotal = 16 } = props;
    const isPhone = useIsPhone();
    const { withBanners, listKey } = usePageContext();
    const banners = useBanners();
    const { error, items = [], loading, loadingMore, query } = useSelectCreditsList(listKey);

    const { asPath } = useRouter();
    const [pathWithoutQuery] = asPath.split('?');
    const isNewCardDesign = isNaKartuPage(pathWithoutQuery);
    const isMainPage = isMainPageWithoutDynamicRoute(pathWithoutQuery);

    const CardComponent = memo(isNewCardDesign ? CardV2 : Card);
    const isOneColumn =
        isNewCardDesign ||
        [specOffersLandingPageRouteWebbankirWb1, specOffersLandingPageRouteWebbankirWb2].includes(pathWithoutQuery);

    const renderSlot = useCallback(
        (index, listPosition = BANNER_LIST_POSITION_INDEX) =>
            withBanners && getBanners({ index, banners, listPosition }),
        [withBanners, banners],
    );

    const renderListContent = useCallback(() => {
        const badgesForMainPage = [...ADDITIONAL_BADGES_FOR_MAIN_PAGE_CARDS];

        const result = items.reduce((output, item, index) => {
            const offer =
                isMainPage && badgesForMainPage.length ? addBadgeForCard(item, badgesForMainPage, isPhone) : item;

            const slot = renderSlot(index, BANNER_LIST_POSITION_INDEX);
            if (slot) {
                output.push(
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={`${banners[BANNER_TYPE].id}-${index}`} className={styles.banner}>
                        {slot}
                    </div>,
                );
            }

            const key = getKey(offer._id, index, listKey);
            output.push(
                <CardComponent
                    key={`p-item-${key}`}
                    offer={offer}
                    position={index}
                    query={{ amount: query.amount, term: query.term }}
                />,
            );

            return output;
        }, [] as React.ReactElement[]);

        return result;
    }, [items, renderSlot, listKey, CardComponent, query, banners, isMainPage, isPhone]);

    const renderList = useCallback(
        () => (
            <>
                <div
                    className={cn(styles.list, {
                        oneColumn: isOneColumn,
                    })}
                >
                    {renderListContent()}
                </div>
                {loadingMore && (
                    <Preloader className={cn(styles.list, { oneColumn: isOneColumn })} itemsCount={preloadTotal} />
                )}
            </>
        ),
        [isOneColumn, renderListContent, loadingMore, preloadTotal],
    );

    if (error) {
        return <MessageBlock {...getErrorText()} />;
    }

    const renderContent = () => {
        const { message, title } = getEmptyProductsTexts(listKey);

        if (!items.length && !loading) {
            return <MessageBlock message={message} title={title} />;
        }
        return loading ? (
            <Preloader className={cn(styles.list, { oneColumn: isOneColumn })} itemsCount={preloadTotal} />
        ) : (
            renderList()
        );
    };

    return (
        <div className={cn(styles.container, className)}>
            <Layout className={styles.layout}>{renderContent()}</Layout>
        </div>
    );
});
