import type { SelectOption } from '@sravni/react-design-system/dist/types/components/Select/types/Select.types';

export const TERM_OPTIONS: SelectOption[] = [
    { label: '2 месяца', value: 60 },
    { label: '3 месяца', value: 90 },
    { label: '4 месяца', value: 120 },
    { label: '5 месяцев', value: 150 },
    { label: '6 месяцев', value: 180 },
    { label: '7 месяцев', value: 210 },
    { label: '8 месяцев', value: 240 },
    { label: '9 месяцев', value: 270 },
    { label: '10 месяцев', value: 300 },
    { label: '11 месяцев', value: 330 },
    { label: '1 год', value: 365 },
];
