import type { IClientMicrocreditListItem } from '@src/@types/clienttypes';

type Badge = {
    desktop: string;
    mobile: string;
};

export const addBadgeForCard = (
    item: IClientMicrocreditListItem,
    badges: Badge[],
    isPhone: boolean,
): IClientMicrocreditListItem => {
    if (item.advertising.labelText) {
        return item;
    }

    const badge = badges.shift();
    const badgeLabel = isPhone ? badge.mobile : badge.desktop;

    return {
        ...item,
        advertising: {
            ...item.advertising,
            labelText: `${badgeLabel};${badgeLabel}`,
        },
    };
};
