export const ADDITIONAL_BADGES_FOR_MAIN_PAGE_CARDS = [
    { desktop: 'Заем на карту онлайн', mobile: 'Заем на карту онлайн' },
    { desktop: 'Интернет-заем', mobile: 'Интернет-заем' },
    { desktop: 'Онлайн заем', mobile: 'Онлайн заем' },
    { desktop: 'Мгновенный заем онлайн', mobile: 'Мгновенный заем' },
    { desktop: 'Онлайн заем', mobile: 'Онлайн заем' },
    { desktop: 'Онлайн заем на карту', mobile: 'Онлайн заем на карту' },
    { desktop: 'Заем онлайн', mobile: 'Заем онлайн' },
    { desktop: 'Заем под 0%', mobile: 'Заем под 0%' },
    { desktop: 'Онлайн заем на карту', mobile: 'Онлайн заем на карту' },
    { desktop: 'Нецелевой заем', mobile: 'Нецелевой заем' },
    { desktop: 'Заем на карту', mobile: 'Заем на карту' },
    { desktop: 'Заем до зарплаты', mobile: 'Заем до зарплаты' },
    { desktop: 'Заем до полугода', mobile: 'Заем до полугода' },
    { desktop: 'Онлайн-заем', mobile: 'Онлайн-заем' },
    { desktop: 'Заем под 0%', mobile: 'Заем под 0%' },
    { desktop: 'Заем под залог ПТС', mobile: 'Заем под залог ПТС' },
    { desktop: 'Микрозайм Стандартный', mobile: 'Микрозайм' },
    { desktop: 'Заем до зарплаты', mobile: 'Заем до зарплаты' },
    { desktop: 'Онлайн-заем', mobile: 'Онлайн-заем' },
    { desktop: 'Заем наличными', mobile: 'Заем наличными' },
    { desktop: 'Целевой заем', mobile: 'Целевой заем' },
];
