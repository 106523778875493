import add from 'date-fns/add';
import { useRouter } from 'next/router';
import React, { useCallback } from 'react';

import { Button, Space, Typography } from '@sravni/react-design-system';
import { Icon } from '@sravni/react-design-system/lib/Icon';
import { Plus } from '@sravni/react-icons';
import { useDeviceInfoContext, useIsMobile } from '@sravni/react-utils';
import type { Seo } from '@sravni/types/lib/seo';
import pluralize from '@sravni/utils/lib/pluralize';

import type * as Microcredits from '@src/@types/microcredits';
import { SessionStorageKeys } from '@src/@types/sessionStorage';
import {
    AMOUNT_LABEL,
    AMOUNT_MAX,
    AMOUNT_MIN_IL_VALUE,
    AMOUNT_SLIDER_STEP,
    TERM_LABEL,
    TERM_MAX,
    TERM_MIN,
} from '@src/components/details/components/PageHeaderFilters/consts';
import { AnchorLinkList } from '@src/components/PageHeader/AnchorLinkList';
import { DESKTOP_BUTTON_SIZE, FILTER_INPUT_LABEL, MOBILE_BUTTON_SIZE } from '@src/components/PageHeader/consts';
import {
    AMOUNT_DEFAULT_VALUE_AB_TEST,
    AMOUNT_MIN_VALUE,
    SRAVNI_LOGO_URL,
    TERM_DEFAULT_VALUE_AB_TEST,
} from '@src/components/PageHeader/SimplePageHeaderFilters/constants';
import { AmountControl } from '@src/components/ui/AmountControlAB';
import { AmountControlV2 } from '@src/components/ui/AmountControlV2';
import { OptimizedPicture } from '@src/components/ui/OptimizedPicture';
import { TermControl } from '@src/components/ui/TermControl';
import { usePageContext } from '@src/config/PageContext';
import { Experiments } from '@src/constants/abTest';
import { PRODUCT_LIST_ANCHOR } from '@src/constants/common';
import { AMOUNT_SETTINGS, FILTERS_NAMES } from '@src/constants/filters';
import {
    FULL_DEAL_ENTRY_POINT_TEXTS,
    ONE_LINK,
    WEB_FULL_DEAL_LINK,
} from '@src/containers/list/ProductList/FullDealEntryPoint/сonstants';
import {
    sendAmountFilterEvent,
    sendOpenAppFullDealEvent,
    sendOpenWebFullDealEvent,
    sendShowProductEvent,
    sendTermFilterEvent,
} from '@src/helpers/analyticsEvents';
import { scrollToElementById } from '@src/helpers/common';
import { useTestVariant } from '@src/hooks/useTestVariants';
import { Cookie, CookieKey } from '@src/modules/cookie';
import { useSelectCreditsList } from '@src/reducers/microcredits';
import { useMicrocreditUserDealsState } from '@src/reducers/microcreditUserDeals';
import { useHasUtmLabel } from '@src/reducers/route';
import { checkIsFullDealBlockVisible } from '@src/utils/checkIsFullDealBlockVisible';
import { TermDropdown } from 'src/components/ui/TermDropdown';

import styles from './styles.module.scss';

interface ISimplePageHeaderFiltersProps {
    className?: string;
    difference?: number;
    filters: Microcredits.IFilters;
    conditionsActive?: boolean;
    onChange: (value: any, name: string) => void;
    onToggleConditionDialog?: () => void;
    seo?: Partial<Seo>;
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}
const { Heading } = Typography;

export const SimplePageHeaderFilters: React.FunctionComponent<ISimplePageHeaderFiltersProps> = React.memo(
    ({ filters, onChange, seo }) => {
        const { listKey } = usePageContext();
        const isMobile = useIsMobile();
        const { phone: isPhone } = useDeviceInfoContext();
        const { total: count = 0 } = useSelectCreditsList(listKey);
        const hasUtmLabel = useHasUtmLabel();
        const microcreditDeals = useMicrocreditUserDealsState();
        const { isBVariant } = useTestVariant(Experiments.FULL_DEAL_ENTRY_BLOCK);
        const { asPath } = useRouter();

        const hasIssuedMicrocreditDeals = microcreditDeals.some((deal) => deal.status === 'issued');

        const [pathWithoutQuery] = asPath.split('?');
        const isFullDealBlockVisible =
            checkIsFullDealBlockVisible(pathWithoutQuery) && isPhone && isBVariant && !hasUtmLabel;

        const termPostfix = pluralize(filters[FILTERS_NAMES.TERM], 'день', 'дня', 'дней') ?? 'день';

        const handleClickSelectionButton = useCallback(() => {
            sendShowProductEvent();

            scrollToElementById(PRODUCT_LIST_ANCHOR);
        }, []);

        const handleOpenWebFullDeal = (event: React.SyntheticEvent) => {
            event.stopPropagation();

            const amount = filters[FILTERS_NAMES.AMOUNT] ?? AMOUNT_DEFAULT_VALUE_AB_TEST;
            const term = filters[FILTERS_NAMES.TERM] ?? TERM_DEFAULT_VALUE_AB_TEST;

            if (amount) {
                Cookie.set(CookieKey.amount, amount.toString(), {
                    expires: add(new Date(), { days: 1 }),
                    path: '/',
                });
            }

            if (term) {
                Cookie.set(CookieKey.term, term.toString(), { expires: add(new Date(), { days: 1 }), path: '/' });
            }

            sessionStorage.setItem(SessionStorageKeys.referer, asPath);

            const buttonLabel = hasIssuedMicrocreditDeals ? `Новый займ (${seo.heading})` : 'Подобрать займ';
            sendOpenWebFullDealEvent(buttonLabel);

            window.location.href = WEB_FULL_DEAL_LINK;
        };

        const handleOpenMobileApp = (event: React.SyntheticEvent) => {
            event.stopPropagation();

            sendOpenAppFullDealEvent(seo?.heading);
            window.open(ONE_LINK, '_blank');
        };

        if (isFullDealBlockVisible) {
            return (
                <div className={styles.container}>
                    <div className={styles.wrapper}>
                        {seo && (
                            <Heading className="h-text-center" level={1}>
                                {seo?.heading}
                            </Heading>
                        )}
                        <div className={styles.input}>
                            <div className={styles.sumField}>
                                <AmountControlV2
                                    analyticsEvent={sendAmountFilterEvent}
                                    steps={[{ ceilThreshold: AMOUNT_MAX, step: AMOUNT_SLIDER_STEP }]}
                                    name={FILTERS_NAMES.AMOUNT}
                                    className={styles.amount}
                                    label={AMOUNT_LABEL}
                                    value={filters[FILTERS_NAMES.AMOUNT] ?? AMOUNT_DEFAULT_VALUE_AB_TEST}
                                    min={AMOUNT_MIN_VALUE}
                                    max={AMOUNT_SETTINGS.max}
                                    onChange={onChange}
                                    postfix="₽"
                                />
                            </div>
                            <div className={styles.sumField}>
                                {(filters[FILTERS_NAMES.AMOUNT] ?? 0) < AMOUNT_MIN_IL_VALUE ? (
                                    <TermControl
                                        name={FILTERS_NAMES.TERM}
                                        label={TERM_LABEL}
                                        value={filters[FILTERS_NAMES.TERM] ?? TERM_DEFAULT_VALUE_AB_TEST}
                                        onChange={onChange}
                                        postfix={termPostfix}
                                        min={TERM_MIN}
                                        max={TERM_MAX}
                                        analyticsEvent={sendTermFilterEvent}
                                    />
                                ) : (
                                    <TermDropdown
                                        analyticsEvent={sendTermFilterEvent}
                                        onChange={onChange}
                                        filters={filters}
                                    />
                                )}
                            </div>

                            {hasIssuedMicrocreditDeals ? (
                                <Space size={8} direction="horizontal">
                                    <Button
                                        className={styles.button}
                                        size={60}
                                        color="gray"
                                        onClick={handleOpenMobileApp}
                                    >
                                        {FULL_DEAL_ENTRY_POINT_TEXTS.mobileAppButtonText}
                                    </Button>
                                    <Button
                                        className={styles.button}
                                        size={60}
                                        variant="primary"
                                        color="gray"
                                        onClick={handleOpenWebFullDeal}
                                    >
                                        <>
                                            <Icon size={20} icon={<Plus />} />
                                            {FULL_DEAL_ENTRY_POINT_TEXTS.newDealButtonText}
                                        </>
                                    </Button>
                                </Space>
                            ) : (
                                <Button
                                    variant="primary"
                                    color="gray"
                                    size={DESKTOP_BUTTON_SIZE}
                                    onClick={handleOpenWebFullDeal}
                                    className={styles.buttonFullWidth}
                                >
                                    Подобрать займ
                                    <OptimizedPicture
                                        imgClassName={styles.logo}
                                        img={SRAVNI_LOGO_URL}
                                        alt="logo"
                                        width={23}
                                        height={20}
                                    />
                                </Button>
                            )}

                            <Button
                                variant="secondary"
                                color="gray"
                                size={DESKTOP_BUTTON_SIZE}
                                onClick={handleClickSelectionButton}
                                className={styles.buttonFullWidth}
                            >
                                Посмотреть предложения
                            </Button>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <>
                {isMobile && !hasUtmLabel && seo && (
                    <Heading className={styles.heading} level={1}>
                        {seo?.heading ?? 'Займы'}
                    </Heading>
                )}

                <div className={styles.wrapper}>
                    <div className={styles.input}>
                        <div className={styles.sumField}>
                            <AmountControl
                                name={FILTERS_NAMES.AMOUNT}
                                className={styles.amount}
                                label={FILTER_INPUT_LABEL}
                                value={filters[FILTERS_NAMES.AMOUNT]}
                                max={AMOUNT_SETTINGS.max}
                                onChange={onChange}
                                postfix="₽"
                            />
                        </div>
                        <Button
                            variant="primary"
                            size={isMobile ? MOBILE_BUTTON_SIZE : DESKTOP_BUTTON_SIZE}
                            onClick={handleClickSelectionButton}
                            className={styles.buttonFullWidth}
                        >
                            Показать {count}
                        </Button>
                    </div>
                    <AnchorLinkList />
                </div>
            </>
        );
    },
);
